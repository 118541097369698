import styled, { css } from 'styled-components';
import { spacing } from '_utils/props';
import mq from '_utils/styles/mq';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { Grid } from '_utils/components/Corporate';
import { externalLinkStyle } from '_components/Corporate/ContentRichText/StyledContentRichText';

function background({ theme, background }) {
  if (background === 'transparent') {
    return css`
      background-color: transparent;
    `;
  }

  return css`
    background-color: ${theme.colors.background[background]};
  `;
}
export const Wrap = styled.div`
  margin: 0;

  ${({ theme }) => spacing({ theme, my: 2 })}
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  ${background}
`;
export const GridContainer = styled(Grid.Container)`
  padding-left: 0;
  padding-right: 0;

  ${mq('lg')} {
    padding-left: 0;
    padding-right: 0;
  }

  ${mq('xl')} {
    padding-left: 0;
    padding-right: 0;
  }

  @media print {
    width: 50%;
    margin: 0;
    page-break-inside: avoid;
  }
`;

export const RichTextHtml = styled(RichText)`
  display: flex;
  justify-content: center;
  width: 100%;
  .external-link {
    ${externalLinkStyle}
  }
`;
