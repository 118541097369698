import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

import PropertiesTab from './PropertiesTab';
import PropertyCertificatesTab from './PropertyCertificatesTab';
import ServiceRequestsTab from './ServiceRequestsTab';
import RegistrationsTab from './RegistrationsTab';
import {
  LAST_30_DAYS,
  PROPERTIES_TAB,
  PROPERTY_CERTIFICATES_TAB,
  REGISTRATIONS_TAB,
  SERVICE_REQUESTS_TAB
} from './constant';
import DashboardTabsHeader from './DashboardTabsHeader';
import { IDashboardTabsProps } from './definitions';
import {
  DashboardTabContentContainer,
  DashboardTabContentWrapper,
  DashboardTabsWrapper
} from './StyledDashboardTabs';

import { getDashboardTabContentData } from '_services/dashboardTabsService';

export const DashboardTabsContext = React.createContext(null);
const LIST_TAB = [
  SERVICE_REQUESTS_TAB,
  REGISTRATIONS_TAB,
  PROPERTIES_TAB,
  PROPERTY_CERTIFICATES_TAB
];

let cancelToken;

const DashboardTabs: React.FC<IDashboardTabsProps> = ({ fields }) => {
  const [t] = useTranslation();
  const location = useLocation();
  const targetTab = location.pathname.split('/').pop();

  const [activeTab, setActiveTab] = useState<string>(
    LIST_TAB.includes(targetTab) ? targetTab : SERVICE_REQUESTS_TAB
  );
  const [currentTab, setCurrentTab] = useState(activeTab);
  const [currentTabContent, setCurrentTabContent] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [currentFilter, setCurrentFilter] = useState({
    [SERVICE_REQUESTS_TAB]: LAST_30_DAYS,
    [PROPERTY_CERTIFICATES_TAB]: LAST_30_DAYS
  });
  const [showMoreLoading, setShowMoreLoading] = useState(false);
  const [filters, setFilters] = useState<[]>([]);

  const tabList = fields?.data?.datasource?.tabs;
  const notificationMsg = tabList?.find((tab) => tab.tabName.value === activeTab)
    ?.notificationMessage?.value;
  const unavailableMessage = tabList?.find((tab) => tab.tabName.value === activeTab)
    ?.unavailableMessage?.value;
  const description = tabList?.find((tab) => tab.tabName.value === activeTab)?.description?.value;
  const tabName = currentTabContent?.tabName;
  const totalItems = currentTabContent?.results?.totalItems?.toString();
  const nextPage = currentTabContent?.results?.nextPage?.toString();
  const items = currentTabContent?.results?.items;
  const showRequest = currentTabContent?.results?.items?.length;

  const changeActiveTab = (tabName: string) => {
    setActiveTab(tabName);
  };

  const changeFilter = async (apiLink: string, filter: string) => {
    setError(false);
    setLoading(true);
    setCurrentTabContent(null);
    setCurrentFilter((prevState) => ({ ...prevState, [activeTab]: filter }));
    //Check if there are any previous pending requests
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.');
    }
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();
    try {
      const data = await getDashboardTabContentData(apiLink, {
        cancelToken: cancelToken.token
      });
      setLoading(false);
      setCurrentTabContent(data?.data?.tab);
    } catch (err) {
      if (!axios.isCancel(err)) {
        setError(err);
        setLoading(false);
      }
    }
  };

  const handleGetMoreItems = async () => {
    //call next page items api
    setError(false);
    setShowMoreLoading((prevState) => true);
    //Check if there are any previous pending requests
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.');
    }
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();

    try {
      const res = await getDashboardTabContentData(nextPage, {
        cancelToken: cancelToken.token
      });
      const newTabContent = res?.data?.tab;

      setShowMoreLoading((prevState) => false);
      setCurrentTabContent((prevState) => ({
        ...newTabContent,
        results: {
          ...newTabContent?.results,
          items: [...prevState?.results?.items, ...newTabContent?.results?.items]
        }
      }));
    } catch (err) {
      if (!axios.isCancel(err)) {
        setShowMoreLoading((prevState) => false);
        setError(err);
      }
    }
  };

  const contextValue = {
    activeTab,
    changeActiveTab,
    currentFilter,
    changeFilter,
    showMoreData: {
      nextPage,
      tabName,
      showRequest,
      totalItems,
      showMoreLoading
    },
    loading,
    error,
    description,
    notificationMsg,
    unavailableMessage,
    handleGetMoreItems
  };

  useEffect(() => {
    //Call API to get tab api when change tab
    const activeFilter = currentFilter[activeTab];

    const fetchingContent = async () => {
      setLoading(true);
      setCurrentTabContent(null);
      setFilters([]);
      setError(false);
      //Check if there are any previous pending requests
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel('Operation canceled due to new request.');
      }
      //Save the cancel token for the current request
      cancelToken = axios.CancelToken.source();

      try {
        const res = await getDashboardTabContentData(
          {
            activeTab,
            activeFilter
          },
          {
            cancelToken: cancelToken.token
          }
        );
        setLoading(false);
        setCurrentTabContent(res?.data?.tab);
      } catch (err) {
        if (!axios.isCancel(err)) {
          setLoading(false);
          setError(err);
        }
      }
    };

    // fetch tab data if tab is not unavailable
    if(!unavailableMessage){
      fetchingContent();
    }

    setCurrentTab(activeTab);
  }, [activeTab]);

  useEffect(() => {
    if (currentTabContent && currentTabContent.filters) {
      setFilters(currentTabContent.filters);
    }
  }, [currentTabContent]);

  const renderTabContent = () => {
    switch (currentTab) {
      case SERVICE_REQUESTS_TAB:
        return <ServiceRequestsTab tabData={items} filters={filters} />;
      case PROPERTY_CERTIFICATES_TAB:
        return <PropertyCertificatesTab tabData={items} filters={filters} />;
      case REGISTRATIONS_TAB:
        return <RegistrationsTab tabData={items} />;
      case PROPERTIES_TAB:
        return <PropertiesTab tabData={items} />;
      default:
        break;
    }
  };

  return (
    <DashboardTabsContext.Provider value={contextValue}>
      <DashboardTabsWrapper>
        <DashboardTabsHeader fields={fields} />
        <DashboardTabContentWrapper>
          <DashboardTabContentContainer isLoading={loading}>
            {renderTabContent()}
          </DashboardTabContentContainer>
        </DashboardTabContentWrapper>
      </DashboardTabsWrapper>
    </DashboardTabsContext.Provider>
  );
};

export default DashboardTabs;
