import styled from 'styled-components';
import { spacing } from '_utils/props';
import {
    button,
    heading_m,
    heading_s,
    heading_xs,
    mq,
    text_m,
    text_xs} from '_utils/styles/';
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { Link } from "@sitecore-jss/sitecore-jss-react";
import {
    Primary as PrimarySingle
} from '_components/CityConnect/SingleColumnCurvedBorder/SingleColumnFrame/StyledSingleColumnFrame';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${props => props.isSingle ? '288px': ''};
  min-width: ${props => props.isSingle ? '288px': ''};
  background: #041C2C;
  border-radius: 8px;
  ${({ theme }) => spacing({ theme, p: 3 })}
  ${mq.lessThan('md')} {
    max-width: ${props => props.isSingle ? '276px': ''};
    min-width: ${props => props.isSingle ? '276px': ''};
  }
`;


export const Title = styled(Text)`
  ${heading_m}
  color: #FFFFFF;
  ${({ theme }) => spacing({ theme, mb: 1 })};
  ${mq.lessThan('md')}{
    line-height: 24px;
  }
`

export const Description = styled(Text)`
  ${text_m}
  color: #DDDEE1;
  ${mq.lessThan('md')}{
    line-height: 22px;
  }
  ${({ theme }) => spacing({ theme, m: 0 })};
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${PrimarySingle} & {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    ${mq.lessThan('sm')}{
        flex-direction: column;
        flex-wrap: no-wrap;
    }
  }
`

export const CTAButton = styled(Link)`
    ${button('default', 'white')};
    transition: 200ms;
    background: #188838;
    border-color: #188838;
    color: #FFFFFF;
    width: 100%;
    ${({ theme }) => spacing({ theme, mt: 2, px: 2, py: 1 })};
    ${heading_s}
    ${mq.lessThan('md')}{
      ${heading_xs}
      ${({ theme }) => spacing({ theme, mt: 3 })};
    }
`;

export const ButtonTitleText = styled(Text)`
  ${heading_s}
  color: ${props => props.color.color};
`

export const Button = styled(Link)`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.color.background};
  border-radius: 16px;
  text-decoration: none;
  ${PrimarySingle} & {
    width: calc(50% - 8px);
    ${mq.lessThan('md')}{
      width: calc(50% - 8px);
    }
    ${mq.lessThan('sm')}{
      width: 100%;
    }
  }
  & svg{
    fill: ${props => props.color.color};
  }
  ${mq.lessThan('md')}{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  ${({ theme }) => spacing({ theme, py: 1, px: 2, mt: 2})}
  &:hover {
    background-color: ${props => props.color.hover};
    & svg{
      fill: #041C2C;
    }
  }
  &:hover ${ButtonTitleText}{
    color: #041C2C;
  }
`

export const ButtonTitle = styled.div`
  display: flex;
  align-items: flex-start;
  & svg{
    ${({ theme }) => spacing({ theme, mr: 1})}
    min-width: 24px;
    max-width: 24px;
  }
  ${mq.lessThan('md')}{
    & svg{
      min-width: 20px;
      max-width: 20px;
    }
  }
`

export const ButtonDescription = styled(Text)`
  ${text_xs}
  ${({ theme }) => spacing({ theme, m: 0})}
  color: #041C2C;
`

export const EditorDiv = styled.div``;
