import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Radio from '_utils/components/Corporate/Radio';
import ClickAwayListener from '_utils/components/CityConnect/ClickAwayListener';

import {
  SearchItemContainer,
  SelectButton,
  SelectCountryContainer,
  SelectItem,
  SelectPanel
} from './StyledSelectCountry';

export interface ICountry {
  iso3: string;
  iso2: string;
  name: string;
}

type SelectCountryProps = {
  visibility: boolean;
  listCountry: Array<ICountry>;
  onChangeCountry: (country: ICountry) => void;
};

const SelectCountry: React.FC<SelectCountryProps> = ({
  visibility,
  listCountry,
  onChangeCountry
}) => {
  const [t] = useTranslation();
  const [isOpenPanel, setIsOpenPanel] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<ICountry>({
    iso3: '',
    iso2: '',
    name: ''
  });
  const [listSearch, setListSearch] = useState<ICountry[]>(listCountry);
  const [searchValue, setSearchValue] = useState(selectedCountry.name);

  // handle click on sort button
  const openDataPanel = (event: SyntheticEvent) => {
    setIsOpenPanel(true);
  };

  // handle click on sort option
  const selectOption = (value: ICountry) => {
    setSelectedCountry(value);
    onChangeCountry(value);
    setIsOpenPanel(false);
  };

  function compare(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  const onChange = (e: any) => {
    let listNew = listCountry.filter((item) => item.name.toLowerCase().startsWith(e.target.value.toLowerCase()));
    setSearchValue(e.target.value);
    setListSearch([...listNew]);
  };

  const onClickAway = () => {
    setIsOpenPanel(false);
    setSearchValue(selectedCountry.name);
  };

  useEffect(() => {
    setSearchValue(selectedCountry.name);
    let listNew = listCountry.filter((item) => item.name.includes(selectedCountry.name));
    setListSearch([...listNew]);
  }, [selectedCountry.name]);

  return visibility ? (
    <ClickAwayListener onClickAway={() => onClickAway()}>
      <SelectCountryContainer>
        <SelectButton
          onClick={openDataPanel}
          value={searchValue}
          onChange={(e) => onChange(e)}
          placeholder={t('personal-info-select-country')}
        ></SelectButton>
        <SelectPanel showPanel={isOpenPanel}>
          <SearchItemContainer>
            {listSearch.sort(compare).map((country, index) => (
              <SelectItem key={index} onClick={() => selectOption(country)}>
                <Radio
                  id={'country-' + index}
                  name="SelectCountry"
                  value={country.iso3}
                  onChange={() => {}}
                  checked={selectedCountry.iso3 === country.iso3}
                >
                  <div>{country.name}</div>
                </Radio>
              </SelectItem>
            ))}
          </SearchItemContainer>
        </SelectPanel>
      </SelectCountryContainer>
    </ClickAwayListener>
  ) : null;
};

export default SelectCountry;
