import React, { ReactNode } from 'react';
import { dateConverter } from '_utils/helpers';

const renderDate = (date: string, yearOnly: boolean): string => {
  const { attrDate, visualDate } = dateConverter(date, { yearOnly: yearOnly, serverDate: true });

  return visualDate;
};

export { renderDate };
